import React from 'react';
import Navbar from '../Components/Common/Navbar';
import PageHead from '../Components/Common/PageHead';

const Career = () => {
	return (
		<div>
			<Navbar transparent={false} />
			<PageHead title='Career' />
			<div className='container py-12'>
				<form
					onSubmit={e => e.preventDefault()}
					className='mt-4 lg:w-2/4 mx-auto shadow-xl bg-white p-6 rounded-lg'>
					<div className='w-full '>
						<label htmlFor='name'>First Name: *</label>
						<input type='text' className='border w-full px-4 py-2  mt-2' />
					</div>
					<div className='w-full  my-2'>
						<label htmlFor='name'>Last Name: *</label>
						<input type='text' className='border w-full px-4 py-2  mt-2' />
					</div>
					<div className='w-full  my-2'>
						<label htmlFor='name'>Email: *</label>
						<input type='email' className='border w-full px-4 py-2  mt-2' />
					</div>
					<div className='w-full  my-2'>
						<label htmlFor='name'>Contact No.: *</label>
						<input type='text' className='border w-full px-4 py-2  mt-2' />
					</div>
					<div className='w-full  my-2'>
						<label htmlFor='name'>Subject: *</label>
						<input type='text' className='border w-full px-4 py-2  mt-2' />
					</div>
					<div className='w-full my-2'>
						<label htmlFor='details'>Details:</label>
						<textarea
							name='details'
							className='w-full mt-3 border '
							id=''
							cols='30'
							rows='10'></textarea>
					</div>
					<div className='w-full my-2'>
						<label htmlFor='file'> File Attachment:*</label>
						<input type='file' className='w-full mt-3' />
					</div>
					<div className='text-center'>
						<input
							type='submit'
							value='Submit'
							className='px-8 rounded-full py-1 mt-4 cursor-pointer bg-gradient-to-b from-primary to-[#118ab7] text-white uppercase'
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Career;
