import React from 'react';
import Marquee from 'react-fast-marquee';

const Partners = () => {
	// const settings = {
	// 	dots: false,
	// 	infinite: true,
	// 	speed: 500,
	// 	slidesToShow: 6,
	// 	slidesToScroll: 1,
	// 	autoplay: true,
	// 	autoplaySpeed: 3000,
	// };
	return (
		<div id='partners' className='bg-white'>
			<div className='container py-8'>
				<h4 className='text-primary uppercase mb-4'>Overseas Partners</h4>
				{/* <Slider {...settings}>
					<div>
						<img src='/img/1.png' className='mx-auto' alt='' />
					</div>
					<div>
						<img src='/img/2.png' className='mx-auto' alt='' />
					</div>
					<div>
						<img src='/img/3.png' className='mx-auto' alt='' />
					</div>
					<div>
						<img src='/img/4.png' className='mx-auto' alt='' />
					</div>
					<div>
						<img src='/img/5.png' className='mx-auto' alt='' />
					</div>
					<div>
						<img src='/img/6.png' className='mx-auto' alt='' />
					</div>
					<div>
						<img src='/img/7.png' className='mx-auto' alt='' />
					</div>
					<div>
						<img src='/img/8.png' className='mx-auto' alt='' />
					</div>
					<div>
						<img src='/img/9.png' className='mx-auto' alt='' />
					</div>
				</Slider> */}
				<Marquee>
					<div className='flex gap-12 ml-12 items-center'>
						<img src='/img/1.png' className='w-24' alt='' />
						<img src='/img/2.png' className='w-24' alt='' />
						<img src='/img/3.png' className='w-24' alt='' />
						<img src='/img/4.png' className='w-24' alt='' />
						<img src='/img/5.png' className='w-24' alt='' />
						<img src='/img/6.png' className='w-24' alt='' />
					</div>
					<div className='flex gap-12 ml-12 items-center'>
						<img src='/img/1.png' className='w-24' alt='' />
						<img src='/img/2.png' className='w-24' alt='' />
						<img src='/img/3.png' className='w-24' alt='' />
						<img src='/img/4.png' className='w-24' alt='' />
						<img src='/img/5.png' className='w-24' alt='' />
						<img src='/img/6.png' className='w-24' alt='' />
					</div>
				</Marquee>
			</div>
		</div>
	);
};

export default Partners;
