import React from 'react';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import PageHead from '../Components/Common/PageHead';

const BusinessPartner = () => {
	return (
		<div>
			<Navbar transparent={false} />
			<PageHead title='Business Partner' />

			<div className='container py-12'>
                <h2 className='text-4xl pb-3'>Business Partner</h2>
                <hr />
				
				<div className="grid grid-cols-2 lg:grid-cols-4 gap-12 mt-8">
					<div className="border">
						<img src="/img/1.png" className='w-4/6 mx-auto' alt="" />
					</div>
					<div className="border">
						<img src="/img/2.png" className='w-4/6 mx-auto' alt="" />
					</div>
					<div className="border">
						<img src="/img/3.png" className='w-4/6 mx-auto' alt="" />
					</div>
					<div className="border">
						<img src="/img/4.png" className='w-4/6 mx-auto' alt="" />
					</div>
					<div className="border">
						<img src="/img/5.png" className='w-4/6 mx-auto' alt="" />
					</div>
					<div className="border">
						<img src="/img/6.png" className='w-4/6 mx-auto' alt="" />
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default BusinessPartner;
