import React from 'react';
import Slider from 'react-slick';
import Navbar from '../Common/Navbar';
import HeaderSliderItem from './HeaderSliderItem';

const Header = () => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 2000,
		fade: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	};
	return (
		<>
			<Navbar />
			<Slider {...settings}>
				{new Array(6).fill('').map((e, i) => <HeaderSliderItem
					key={i}
					img={`/img/slider/${i + 1}.JPG`}
				/>)}
			</Slider>
		</>
	);
};

export default Header;
