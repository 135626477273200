import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import PageHead from '../Components/Common/PageHead';

const Dyes = () => {
	return (
		<>
			<Navbar transparent={false} />
			<PageHead title='Dyes and Chemicals' />
			<div className='bg-[#F5F5F5] py-8'>
				<div className='container'>
					<div className='grid grid-cols-1 md:grid-cols-4 justify-center gap-4'>
						<div></div>
						<Link to="/products/29" data-aos-duration="300"
							data-aos="zoom-in-right">
							<div className='relative group  border-transparent border-4 hover:border-primary rounded'>
								<div
									className='h-[200px] w-full bg-cover bg-center border-2 border-primary'
									style={{ backgroundImage: 'url("/img/c2.jpg")' }}
								/>
								<button className='bg-gradient-to-r from-primary to-[#118ab7] absolute bottom-5 py-2 px-4 text-white -ml-4 group-hover:ml-3  transition-all duration-300'>
									More +
								</button>
							</div>
							<h3 className='text-lg px-3 text-primary mt-2 font-semibold text-center '>
								Dyes
							</h3>
						</Link>
						<Link to="/products/30" data-aos-duration="600"
							data-aos="zoom-in-right">
							<div className='relative group  border-transparent border-4 hover:border-primary rounded'>
								<div
									className='h-[200px] w-full bg-cover bg-center border-2 border-primary'
									style={{ backgroundImage: 'url("/img/c3.jpg")' }}
								/>
								<button className='bg-gradient-to-r from-primary to-[#118ab7] absolute bottom-5 py-2 px-4 text-white -ml-4 group-hover:ml-3  transition-all duration-300'>
									More +
								</button>
							</div>
							<h3 className='text-lg px-3 text-primary mt-2 font-semibold text-center '>
								Chemical
							</h3>
						</Link>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Dyes;
