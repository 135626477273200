import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../Components/Common/Footer';
import Navbar from '../../Components/Common/Navbar';
import PageHead from '../../Components/Common/PageHead';

// const products = [
// 	{
// 		title: ' Ball Warping I/O Ball',
// 		slug: 'morrison_ball',
// 		description: `Morrison Denim Systems incorporate proven technology blended with
//         process experience and quality control. Our MDS-BW 450 delivers to the rope range a first quality ball with a programmed profile.`,
// 		manufacturer: 'MORRISON TEXTILE MACHINERY CO',
// 		images: [
// 			{
// 				url: '/img/ball1.jpg',
// 				alt: '',
// 			},
// 			{
// 				url: '/img/ball2.jpg',
// 				alt: '',
// 			},
// 			{
// 				url: '/img/ball3.jpg',
// 				alt: '',
// 			},
// 		],
// 	},
// 	{
// 		title: 'Denim Finishing Range',
// 		slug: 'morrison_denim-finish',
// 		description: `Aside from being a critical process, increased flexibility is needed to meet the
// 		ever-changing styling requirements of today’s designers. Morrison having decades
// 		of experience, understands this and incorporate features to meet any requirement,
// 		providing consistent performance over decades, not years.`,
// 		manufacturer: 'MORRISON TEXTILE MACHINERY CO',
// 		images: [
// 			{
// 				url: '/img/_MG_6026.JPG',
// 				alt: '',
// 			},
// 		],
// 	},
// 	{
// 		title: 'Long Chain Beamer MDS-RB 550',
// 		slug: 'morrison_long-chain',
// 		description: `Morrison Denim Systems incorporate proven technology blended with process
// 		experience and quality control. Our MDS-RB 550 Long Chain Beamer provides superior rope yarn opening and offers a single source of process responsibility for rope dyeing.`,
// 		manufacturer: 'MORRISON TEXTILE MACHINERY CO',
// 		images: [
// 			{
// 				url: '/img/_MG_6324.JPG',
// 				alt: '',
// 			},
// 			{
// 				url: '/img/_MG_6326.JPG',
// 				alt: '',
// 			},
// 		],
// 	},
// 	{
// 		title: 'Indigo Dye Range',
// 		slug: 'morrison_rope',
// 		description: `At Morrison, we are best known for the legendary durability of our equipment. Itis our critical thinking that makes it all possible. Given the opportunity to work with you from the projects inception, we're able to leverage our 50 years of denim experience. The end result is an Indigo Rope Dye Range configuration specifically designed to meet your unique needs.`,
// 		manufacturer: 'MORRISON TEXTILE MACHINERY CO',
// 		images: [
// 			{
// 				url: '/img/_MG_5929.JPG',
// 				alt: '',
// 			}
// 		],
// 	},
// 	{
// 		title: 'Compressive Shrinking Range',
// 		slug: 'morrison_sanforizing',
// 		description: `With decades of compressive shrinking experience from hundreds of
// 		installations on five continents, we are recognized worldwide as the
// 		benchmark of quality. While much of our reputation centers around handling
// 		the most difficult fabrics, robust design, high production speeds, ease
// 		of operation and low maintenance, let us show you the new advances
// 		that put Morrison ahead of the others.`,
// 		manufacturer: 'MORRISON TEXTILE MACHINERY CO',
// 		images: [
// 			{
// 				url: '/img/_MG_6115.JPG',
// 				alt: '',
// 			},
// 		],
// 	},
// 	{
// 		title: 'Ukil Warping Creel',
// 		slug: 'ukil',
// 		manufacturer: 'UKIL',
// 		description: (
// 			<p className='text-left'>
// 				<h2 className='text-2xl'>Auto Tension Creel</h2>
// 				Auto Tension Creel, Ul- PT-C and Ul- OP-C, guarantees high weaving
// 				efficiency trough an automatic tension control system. The UI-PT-C'and
// 				Ul-OP-C tension system automatically adjusts the applied tension to
// 				maintain the programmed yarn tension with an accuracy of +2CN:`,
// 				manufacturer: 'UKIL MACHINERY CO. LTD.
// 			</p>
// 		),
// 		images: [
// 			{
// 				url: '/img/image5asf.png',
// 				alt: '',
// 			},
// 		],
// 	},
// 	{
// 		title: 'Tsudakoma Sizing Machine',
// 		slug: 'tsudakoma_sizing',
// 		manufacturer: 'TSUDAKOMA',
// 		description: '',
// 		images: [
// 			{
// 				url: '/img/Tsudakoma sizing machine.jpg',
// 				alt: '',
// 			},
// 		],
// 	},
// 	{
// 		title: 'Tsudakoma Weaving Machine',
// 		slug: 'tsudakoma_weaving',
// 		manufacturer: 'TSUDAKOMA',
// 		description: '',
// 		images: [
// 			{
// 				url: '/img/tsudakoma_weaving.png',
// 				alt: '',
// 			},
// 		],
// 	},
// 	{
// 		title: 'Unicorn Sewing Machine',
// 		slug: 'unicorn',
// 		description: '',
// 		manufacturer: 'UNICORN',
// 		images: [
// 			{
// 				url: '/img/Single-Needle-Ultra-High-Speed-Electronic-Direct-Drive-Lockstitch-Sewing-Machine-with-Thread-Trimmer.jpg',
// 				alt: '',
// 			},
// 		],
// 	},
// 	{
// 		title: 'Cone Winding Machine',
// 		slug: 'cone',
// 		description: `This machine is mainly used for directly unwinding the leasing pan head dyed yarn of the ball warp dyeing. Turn the yarn into cheese, which has features as large package, less ends, small color difference, less abrasion on the yarn, evenly waxing, good molding, easy to unwind, etc. It can satisfy the requirement of yarn for knitted fabric.
// 		`,
// 		manufacturer: 'CONE',
// 		images: [
// 			{
// 				url: '',
// 				alt: '',
// 			},
// 		],
// 	},
// 	{
// 		title: 'TEMSAN AIR ENGINEERING',
// 		slug: 'temsan',
// 		description: `Since 1960, Temsan is with the aim to ensure that your textile mill produces at the highest efficiency. As one of the biggest manufacturers, Temsan offers complete process solution in textile humidification system, highest reliability for efficiently performance, energy saving and quality of your mill.
// 		`,
// 		manufacturer: 'TEMSAN',
// 		images: [
// 			{
// 				url: '/img/temsan.png',
// 				alt: '',
// 			},
// 			{
// 				url: '/img/temsan2.png',
// 				alt: '',
// 			},
// 		],
// 	},
// 	{
// 		title: 'Stenter Machine',
// 		slug: 'stenter',
// 		description: (
// 			<div className='text-left capitalize'>
// 				A stenter machine is used in the wet processing of the Denim, Wovwn,
// 				Knitting fabrics. It is a multi-purpose wet processing machine.
// 				<ul className='list-disc'>
// 					<li>
// 						{' '}
// 						main objective of a hot air stenter machine is to dry the processed
// 						fabric.{' '}
// 					</li>
// 					<li> apply the finishing chemicals to the fabric. </li>
// 					<li> pad the fabric with a pigment colour. </li>
// 					<li> recover the fabric width shrunk in the dyeing process. </li>
// 					<li> improve the dimensional stability of the fabrics. </li>
// 					<li> cure the pigment printed fabrics. </li>
// 					<li>
// 						{' '}
// 						make the structure of the synthetic fabrics by the heat-setting
// 						process.{' '}
// 					</li>
// 					<li> rectify the bowing or skewing present in the fabric. </li>
// 				</ul>
// 			</div>
// 		),
// 		manufacturer: 'CONE',
// 		images: [
// 			{
// 				url: '',
// 				alt: '',
// 			},
// 		],
// 	},
// ];

const ProductSingle = () => {
	const { id, subId } = useParams();
	const [productData, setProductData] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const url = `https://nazadmin.icicle.dev/${id ? `cat-Details/${id}` : `sub-Details/${subId}`}`;
		fetch(url)
			.then(res => res.json())
			.then(data => {
				setProductData(data.data);
				setLoading(false);
			}).finally(() => setLoading(false));
	}, [id, subId]);
	console.log(productData);
	if (productData === null) {
		// body background color change css
		document.body.style.backgroundColor = '#fff';
	}

	return (
		<>
			<Navbar transparent={false} />
			{productData && <PageHead title={productData?.category?.name} />}
			<div className='container py-12 text-center'>
				<div className='text-center'>
					{!productData ? (
						<div className="flex items-center justify-center">
							<img src={`/img/${loading ? 'searching' : '404'}.gif`} alt="" />
						</div>
					) : (
						<>
							{/* react inner html */}
							<div id='htmlDetails' dangerouslySetInnerHTML={{ __html: productData?.description }} />
						</>
					)}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default ProductSingle;
