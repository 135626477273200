import React from 'react';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import PageHead from '../Components/Common/PageHead';

const Organogram = () => {
	return (
		<div>
			<Navbar transparent={false} />
			<PageHead title='Organogram' />

			<div className='container py-12'>
                <h2 className='text-4xl pb-3'>Organogram</h2>
                <hr />
				<img src='/img/bd1.png' alt='' className='w-4/6 mx-auto' />
			</div>
			<Footer />
		</div>
	);
};

export default Organogram;
