import React from 'react';

const HeaderSliderItem = ({ img, title, subTitle }) => {
	return (
		<div className='relative overflow-hidden'>
			<div className='h-[200px] lg:h-[500px] bg-cover bg-center bg-no-repeat w-full border-b-[1rem] border-primary' style={{backgroundImage:`url("${img}")`}}></div>
			{/* <img src={img} width='100%' alt='' /> */}
			{/* <div className='absolute -left-10 bottom-10 hidden lg:block '>
				<div className=''>
					<h2 className='text-lg lg:text-3xl text-right px-6 py-4 text-white slider-content relative'>
						{title}
					</h2>
				</div>
				<div>
					<h3 className='text-xs lg:text-sm text-right px-6 py-4 text-white slider-content-small'>
						{subTitle}
					</h3>
				</div>
			</div> */}

			{/* <div className='absolute bottom-10 block lg:hidden w-full'>
				<div className='w-4/6 bg-black bg-opacity-50 mx-auto p-4'>
					<h2 className='text-lg lg:text-3xl text-center text-white'>
						{title}
					</h2>
					<h3 className='text-xs lg:text-sm text-center text-white'>
						{subTitle}
					</h3>
				</div>
			</div> */}
		</div>
	);
};

export default HeaderSliderItem;
