import React from 'react';
import { Link } from 'react-router-dom';

function DropDownLink({ link, text }) {
	return (
		<li>
			<Link
				to={link}
				className='hover:bg-gradient-to-b from-primary to-[#118ab7] min-w-max block px-6 py-1.5'>
				{text}
			</Link>
		</li>
	);
}

export default DropDownLink;
