import React from 'react';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import PageHead from '../Components/Common/PageHead';

const FactSheet = () => {
	return (
		<div>
			<Navbar transparent={false} />
			<PageHead title='Fact Sheet' />
			<div className='container py-12'>
				<table class='table table-striped table-hover table-bordered table-condensed table-responsive table-auto'>
					<tbody>
						<tr>
							<td width='227'>Particulars</td>
							<td style={{ textAlign: 'center', width: '10px' }}>:</td>
							<td width='660'>Facts</td>
						</tr>
						<tr>
							<td>Official Name</td>
							<td style={{ textAlign: 'center' }}>:</td>
							<td>
								Naz Corporation
							</td>
						</tr>
						<tr>
							<td>Year of Establishment</td>
							<td style={{ textAlign: 'center' }}>:</td>
							<td>1987</td>
						</tr>
						<tr>
							<td>Business Type</td>
							<td style={{ textAlign: 'center' }}>:</td>
							<td>
								Manufacturer, Exporter, Importer, Supplier, Trader, Service Provider and Distributor of Dyes and Chemicals
							</td>
						</tr>
						<tr>
							<td>Head Office Address</td>
							<td style={{ textAlign: 'center' }}>:</td>
							<td>
								House-71 (2nd floor)
								<br />
								Road-12, Block-H, Banani.
								<br />
								Dhaka 1213, Bangladesh.
							</td>
						</tr>
						<tr>
							<td>Telephone &amp; Email Contact</td>
							<td style={{ textAlign: 'center' }}>:</td>
							<td>
								<p>+88 01711 110203</p>
								<p>rumon22@gmail.com, info@nazcorporation.com </p>
							</td>
						</tr>
						<tr>
							<td>Website URL</td>
							<td style={{ textAlign: 'center' }}>:</td>
							<td>http://www.nazcorporation.com</td>
						</tr>
						<tr>
							<td>Number of Employees</td>
							<td style={{ textAlign: 'center' }}>:</td>
							<td>100</td>
						</tr>
						<tr>
							<td>Key Products</td>
							<td style={{ textAlign: 'center' }}>:</td>
							<td>
								Computerized Embroidery machine, Metal Detector, ETP, CAD/CAM
								solutions, Laboratory equipment, Fabric Finishing, Cutting and
								Washing machine, Fabric Dyeing machines, Compressed Air Solution
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<Footer />
		</div>
	);
};

export default FactSheet;
