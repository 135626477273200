import React from 'react';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import PageHead from '../Components/Common/PageHead';

const Contact = () => {
	return (
		<div>
			<Navbar transparent={false} />
			<PageHead title='Contacts Us' />
			<div className='container py-12'>
				<iframe
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29205.779663653662!2d90.36339730323532!3d23.792895612361473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c701b9c34323%3A0x62bab63e9ad77d87!2sNAZ%20CORPORATION!5e0!3m2!1sen!2sbd!4v1680156044007!5m2!1sen!2sbd'
					className='w-full'
					height='450'
					title='Map'
					allowfullscreen=''
					loading='lazy'
					referrerpolicy='no-referrer-when-downgrade'></iframe>
				<hr />
				<div className='grid grid-cols-1 lg:grid-cols-2 mt-8'>
					<div>
						<h3 className='text-3xl mb-4'>DHAKA OFFICE</h3>
						<table>
							<tbody>
								<tr>
									<th className='px-2'>Address: </th>
									<td>
									House - 06, Suit: D5, 
										<br />
									</td>
								</tr>
								<tr>
									<th></th>
									<td>
									Road - 33, Gulshan - 1, 
										<br />
										Dhaka - 1212, Bangladesh.
									</td>
								</tr>
								{/* <tr>
									<th>Phone:</th>
									<td>+88 01711 110203 </td>
								</tr> */}
								<tr>
									<th>E-Mail:</th>
									<td>
										<a href='mailto:rumon22@gmail.com'>
										rumon22@gmail.com
										</a>
										 
										, <a href='mailto:info@nazcorporation.com'>
										 info@nazcorporation.com
										</a>
									</td>
								</tr>
								<tr>
									<th>Hot Line:</th>
									<td> +8801701443454. </td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className='mt-8'>
						<p>Get in touch with us</p>
						<input
							type='text'
							placeholder='NAME'
							className='w-full p-2 my-3 border border-gray-500'
						/>
						<input
							type='email'
							placeholder='EMAIL'
							className='w-full p-2 my-3 border border-gray-500'
						/>

						<textarea
							name=''
							placeholder='EMAIL'
							className='w-full p-2 my-3 border border-gray-500'
							id=''
							cols='30'
							rows='10'></textarea>

						<button className='bg-primary px-4 py-2 text-white'>
							Send Message
						</button>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Contact;
